import "../node_modules/font-awesome/css/font-awesome.min.css"
import "../node_modules/bulma/css/bulma.css"
import "../node_modules/@fontsource/poppins/200.css"
import "../node_modules/@fontsource/poppins/400.css"
import "../node_modules/@fontsource/poppins/700.css"
import "../node_modules/@fontsource/rubik/400.css"
import "../node_modules/@fontsource/rubik/700.css"

import "./css/main.css"
import "./css/datepicker.css"
import { Elm } from "./Main.elm"
import "./js/autofocus"
import * as Session from "./js/session"

const flags = {
    token: Session.get().withDefault("")
}

const app = Elm.Main.init({ flags })

app.ports.print.subscribe(_ => {
    // We need to explicitly load all fonts only used in the quote for them to
    // be already available on print
    const fontLoadPromises = [
        document.fonts.load('200 16px "Poppins"'),
        document.fonts.load('400 16px "Poppins"'),
        document.fonts.load('700 16px "Poppins"'),
        document.fonts.load('400 16px "Rubik"'),
        document.fonts.load('700 16px "Rubik"')
    ]
    Promise.all(fontLoadPromises).then(() => {
        // we need to scroll up before printing because chrome adds a negative
        // vertical margin on all pages when the page is scrolled down even a
        // little
        window.scrollTo(0, 0)
        window.print()
    })
})

app.ports.copyToClipboard.subscribe(_ => {
    navigator.clipboard.writeText(_)
})

app.ports.saveSession.subscribe(token => {
    Session.save(token)
    app.ports.sessionSaved.send(token)
})

app.ports.deleteSession.subscribe(Session.forget)
